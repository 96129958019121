<!--页脚-->
<template>
    <div>

        <!--定义水平线-->
        <hr>
        <div class="row">

            <div class="col">
                <a href="http://beian.miit.gov.cn/"  class="text-dark" target="_blank">
                    陕ICP备19001223号-3
                </a>
            </div>

		</div>
        <br>

        <div align="center">
            <p>© 2018-现在 <a href="https://www.qingju.com.cn/" class="text-secondary" target="_blank">青橘公司</a> 版权所有</p>
        </div>

        <div align="center">
            <img src="../assets/img/qrcode.png" />
            <br>
            <p>青橘公司网址二维码-欢迎保存分享</p>
        </div>


	</div>

</template>

<script>
    export default {
        data() {
            return {
                year: ''
            }
        },
        computed: {

        }

    }
</script>