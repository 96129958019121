import { createApp } from "vue";
import axios from 'axios'
import VueAxios from 'vue-axios'
import global from "./global";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'font-awesome/css/font-awesome.min.css';
import "../../assets/css/common_style.css";

import index_view from "./index_view.vue";

//vue3中，如果要引入全局变量：app.config.globalProperties.$global = global; 将这句话加到use语句之前。
//vue3中，如果要使用axios，需要首先给工程里安装npm install --save vue-axios，然后如下use。在vue文件里还需要一个props
//.mount的作用，是把这个vue实例，挂在在html的app这个dom节点上。
const app = createApp(index_view, {axios:axios});
app.config.globalProperties.$global = global;
app.config.globalProperties.$router = router;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$store = store;
app.use(router).use(store).use(VueAxios, axios).mount("#app");

