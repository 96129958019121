<!--页眉-->
<template>
    <div>
		
		<div class="dark_overlay container-fluid">
			<div class="row justify-content-around">

				<!--分了5列。-->
				<div class="col-md-2"></div>

				<!--mb-3：m，margin外边距。p，padding内填充。t,b,l,r,x,y含义分别是top,bottom,left,right,mplf一起,mpbt一起。最大5-->
				<div class="col-md-5 align-self-center mt-3 mb-3">
					<h1 class="font-weight-bold">青 橘 企 业 服 务</h1>
				</div>

				<div class="col-md-1 align-self-center mt-3 mb-3">
					<img src="../assets/img/phone.png" />
				</div>

				<div class="col-md-3 align-self-center mt-3 mb-3">
					<h3>4000119665</h3>
					<h5>17789119665（同微信）</h5>
				</div>

				<div class="col-md-1"></div>

			</div>
		</div>
		

        <!--定义水平线-->
        <hr>
    </div>

</template>


<script>
    export default {
        data() {
            return {

            }
        },

        computed: {

        },

    };
</script>


<style>
	
	.self-padding-1 {
		padding: 50px 200px 0px 200px;
	}
	
	.self-padding-2 {
		padding: 50px 10px 0px 100px;
	}
	
	.self-padding-3 {
		padding: 50px 10px 0px 10px;
	}
	
</style>
