import { createRouter, createWebHashHistory } from "vue-router";

import home from "../../components/home.vue";


const routes = [
    //主页
    {
      path: "/",
      name: "home",
      component: home
    },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
