<template>
	<!-- 这个id与main.js里的挂载没关系。 -->
	<div id="index_view">
		<div class="container">
			<app-header></app-header>
			<br>
			<br>
			<router-view></router-view>
			<br>
			<br>
			<app-footer></app-footer>
		</div>
	</div>
</template>

<script>
	import header from '../../components/header.vue';
	import footer from '../../components/footer.vue';
	
	export default {
		name: "app",
		data() {
			return {

			}
		},
		
		components: {
			//这个命名方式不能改。
			appHeader: header,
			appFooter: footer
		},

	};
	
</script>