<!--页脚-->
<template>
    <div class="common-grid">
		<div class="row mb-2 common-grid-size" v-for="item in items">
			<div class="card" style="width:200px">
			  <img class="card-img-top" :src="item.qrcode" alt="Card image">
			  <div class="card-body">
			    <h4 class="card-title">{{item.name}}</h4>
			    <!-- <p class="card-text">Some example text.</p> -->
			  </div>
			</div>
		</div>

	</div>

</template>

<script>
    export default {
        data() {
            return {
				items:[]
            }
        },
		
        computed: {

        },
		
		mounted() {
			{
				this.$axios.get(this.$global.BASE_URL + 'api/all_customer_list/')
					.then(res => {
						if (res.data.count === 0) {
						} else {
							this.items = res.data.results;
							console.log(this.items)
						}
					})
					.catch(err => {
						this.res = err;
						//console.log(err);
					});
		
		    }
		}

    }
</script>