//import vue from "vue";
//import vuex from 'vuex';
import { createStore } from 'vuex';

//vue.use(vuex);

//注意，vuex不适合处理层次深的全局变量，会有问题。如果层次太深的全局变量或者常量，还是放在global里边。
export default createStore({

    state: {
		//可以打桩测试。
		user_info: {

			id: null,
			last_login: null,
			username: null,
			first_name: null,
			last_name: null,
			email: null,
			date_joined: null,
			login_ip: null,
			login_coordinate: null,
			login_recornd: null,
			tel: null,
			wx: null,
			qq: null,
			id_number: null,
			weixin_picurl: null,
			job_number: null,
			job_company: null,
			job_department: null,
			job_position: null,
			job_title: null,
			job_phone: null,
			job_fax: null,
			job_address: null,
			job_postcode: null,
			job_score: null,
			user_points: null,
			user_group: null,
			user_level: null,
			user_exception: null,
			weixin_openid: null,
			weixin_unionid: null,
			weixin_nickname: null,
			weixin_sex: null,
			weixin_province: null,
			weixin_city: null,
			weixin_country: null,
			weixin_headimgurl: null,

			state: 'fail',

		},
    },

    mutations: {
		set_user_info(state, dict) {
			state.user_info = dict;
			state.user_info.state = 'success';
			console.log("函数输出");
		},

		clear_user_info(state){
			state.user_info = {

			id: null,
			last_login: null,
			username: null,
			first_name: null,
			last_name: null,
			email: null,
			date_joined: null,
			login_ip: null,
			login_coordinate: null,
			login_recornd: null,
			tel: null,
			wx: null,
			qq: null,
			id_number: null,
			weixin_picurl: null,
			job_number: null,
			job_company: null,
			job_department: null,
			job_position: null,
			job_title: null,
			job_phone: null,
			job_fax: null,
			job_address: null,
			job_postcode: null,
			job_score: null,
			user_points: null,
			user_group: null,
			user_level: null,
			user_exception: null,
			weixin_openid: null,
			weixin_unionid: null,
			weixin_nickname: null,
			weixin_sex: null,
			weixin_province: null,
			weixin_city: null,
			weixin_country: null,
			weixin_headimgurl: null,

			state: 'fail',

			};
		},
    },

    actions: {

    }
});